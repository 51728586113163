/*
 * @Author: your name
 * @Date: 2022-04-01 10:22:33
 * @LastEditTime: 2022-06-07 16:31:07
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import isweixin from "@/untils/isweixin.js"
Vue.use(VueRouter)
//解决路由跳转报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect: "/index"
  },
  // 引导页
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/guide/index.vue'),
    meta: {
      title: "引导页"
    }
  },
  // 包含导航栏的框架页面
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/components/layout/index.vue'),
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/selectpack",
        name: "selectpack",
        component: () => import("@/views/selectpack/index.vue"),
        meta: {
          title: "套餐办理"
        }
      },
      // 我的页面
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index.vue'),
        meta: {
          title: "我的"
        }
      }
      
    
    ]

  },
  //  iframebox 售后服务
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/service/"),
    meta: {
      title: "锐wifi-客服中心"
    }
  },
  // 付款成功 buysuccess
  {
    path: "/buysuccess",
    name: "buysuccess",
    component: () => import("@/views/buysuccess/"),
    meta: {
      title: "支付成功"
    }
  },
  // 如果不是在微信环境下的提示
  {
    path: "/ctoast",
    name: "ctoast",
    component: () => import("@/components/ctoast/"),
    meta: {
      title: "请用微信打开"
    }
  },
  // // 套餐办理
  // {
  //   path:"/selectpack",
  //   name:"selectpack",
  //   component:() => import("@/views/selectpack/index.vue"),
  //   meta:{
  //     title:"套餐办理"
  //   }
  // }
  // 订单页面
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: "订单详情"
    }
  },
  // 余额充值
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("@/views/recharge/index.vue"),
    meta: {
      title: "余额充值"
    }
  },
  //  // 切换设备
  // {
  //   path:"/switchwifi",
  //   name:"switchwifi",
  //   component:() => import("@/views/switchwifi/index.vue"),
  //   meta:{
  //     title:"切换设备"
  //   }
  // },
  // 关于我们
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("@/views/aboutus/index.vue"),
    meta: {
      title: "关于我们"
    }
  },
  // 意见反馈
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("@/views/feedback/index.vue"),
    meta: {
      title: "意见反馈"
    }
  },
  // 账单明细
  {
    path: "/bill",
    name: "bill",
    component: () => import("@/views/bill/index.vue"),
    meta: {
      title: "余额明细"
    }
  },
  // 用户协议
  {
    path: "/termsService",
    name: "termsService",
    component: () => import("@/views/termsService/index.vue"),
    meta: {
      title: "用户协议"
    }
  },
  // 隐私政策
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/privacy/index.vue"),
    meta: {
      title: "隐私政策"
    }
  },
  // 设置页面
  {
    path: "/setup",
    name: "setup",
    component: () => import("@/views/setup/index.vue"),
    meta: {
      title: "设置"
    }
  },
  // 用户资料
  {
    path: "/userdata",
    name: "userdata",
    component: () => import("@/views/userdata/index.vue"),
    meta: {
      title: "个人资料"
    }
  },
  {
    path: "/frontpage",
    name: "frontpage",
    component: () => import("@/views/frontpage/index.vue"),
    meta: {
      title: "测试获取"
    }
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/download/index.vue"),
    meta: {
      title: "通讯网服"
    }
  },
  {
    path: "/dataPlus",
    name: "dataPlus",
    component: () => import("@/views/dataPlus/index.vue"),
    meta: {
      title: "流量加油包"
    }
  },
  {
    path: "/distributionIndex",
    name: "distributionIndex",
    component: () => import("@/views/distributionIndex/index.vue"),
    meta: {
      title: "首页"
    }
  }, {
    path: "/distributionLogin",
    name: "distributionLogin",
    component: () => import("@/views/distributionLogin/index.vue"),
    meta: {
      title: "分销登录"
    }
  },
  {
    path: "/distributionCardList",
    name: "distributionCardList",
    component: () => import("@/views/distributionCardList/index.vue"),
    meta: {
      title: "卡片列表"
    }

  }, {
    path: "/sales",
    name: "sales",
    component: () => import("@/views/sales/index.vue"),
    meta: {
      title: "分销加盟"
    }
  },
  {
    path: "/uploadOeder",
    name: "uploadOeder",
    component: () => import("@/views/uploadOeder/index.vue"),
    meta: {
      title: "上传订单"
    }
  },
  {
    path: "/selfDispath",
    name: "selfDispath",
    component: () => import("@/views/selfDispath/index.vue"),
    meta: {
      title: "自提发货"
    }
  },
  {
    path: "/oderUpload",
    name: "oderUpload",
    component: () => import("@/views/oderUpload/index.vue"),
    meta: {
      title: "上传文件"
    }
  },
  {
    path: "/cancelCard",
    name: "cancelCard",
    component: () => import("@/views/cancelCard/index.vue"),
    meta: {
      title: "销卡明细"
    }
  },
  {
    path: "/mobileLogin",
    name: "mobileLogin",
    component: () => import("@/views/mobileLogin/index.vue"),
    meta: {
      title: "登录"
    }
  },
  {
    path: "/bindAccounts",
    name: "bindAccounts",
    component: () => import("@/views/bindAccounts/index.vue"),
    meta: {
      title: "绑定支付宝账号"
    }
  },
  {
    path: "/addBankCard",
    name: "addBankCard",
    component: () => import("@/views/addBankCard/index.vue"),
    meta: {
      title: "添加银行卡"
    }
  },
  {
    path: '/Withdraw',
     name: 'Withdraw',
    component: () => import('@/views/Withdraw/index.vue'),
     meta:{
     title:"提现"
     }
  },
  {
    path: '/WithdrawInfo',
     name: 'WithdrawInfo',
    component: () => import('@/views/WithdrawInfo/index.vue'),
     meta:{
     title:"提现明细"
     }
  },
  {
    path: '/scanLogin',
     name: 'scanLogin',
    component: () => import('@/views/scanLogin/index.vue'),
     meta:{
     title:"扫码登录"
     }
  },
  {
    path: '/addBankCardInfo',
     name: 'addBankCardInfo',
    component: () => import('@/views/addBankCardInfo/index.vue'),
     meta:{
     title:"添加银行卡"
     }
  },
  {
    path:'/downLoadcenter',name:'downLoadcenter',
    component:()=>import('@/views/downLoadcenter/index.vue'),
    meta:{
      title:"下载中心"
      }
  },
  {
    path:'/InsteadOfOrder',name:'InsteadOfOrder',
    component:()=>import('@/views/InsteadOfOrder/index.vue'),
    meta:{
      title:"代客下单"
      }
  },
  {
    path:'/authentication',name:'Authentication',
    component:()=>import('@/views/authentication/index.vue'),
    meta:{
      title:"实名认证查询"
      }
  },
  {
    path:'/uploadRecord',name:'uploadRecord',
    component:()=>import('@/views/uploadRecord/index.vue'),
    meta:{
      title:"上传记录"
      }
  },

  {
    path:'/sharePage',name:'sharePage',
    component:()=>import('@/views/sharePage/index.vue'),
    meta:{
      title:"分享页"
      }
  },
  {
    path:'/allotPage',name:'allotPage',
    component:()=>import('@/views/allotPage/index.vue'),
    meta:{
      title:"分配页"
      }
  },
  {
    path:'/bigFlowPage',name:'bigFlowPage',
    component:()=>import('@/views/bigFlowPage/index.vue'),
    meta:{
      title:"中国联通线上转享100G通用大流量卡"
      }
  },
  {
    path:'/coupon',name:'coupon',
    component:()=>import('@/views/coupon/index.vue'),
    meta:{
      title:"优惠券"
      }
  },
  {
    path:'/postShare',name:'postShare',
    component:()=>import('@/views/postShare/index.vue'),
    meta:{
      title:"分享权益"
      }
  }
  ,
  {
    path:'/selectpackCopy',name:'selectpackCopy',
    component:()=>import('@/views/selectpackCopy/index.vue'),
    meta:{
      title:"套餐选择"
      }
  }
  ,
  {
    path:'/couponCopy',name:'couponCopy',
    component:()=>import('@/views/couponCopy/index.vue'),
    meta:{
      title:"优惠券列表"
      }
  },
  {
    path:'/hk',name:'hk',
    component:()=>import('@/views/hk/index.vue'),
    meta:{
      title:"换卡通知"
      }
  },
  {
    path:'/hq',name:'hq',
    component:()=>import('@/views/hq/index.vue'),
    meta:{
      title:"换卡通知"
      }
  }
  // couponCopy
  // bigFlowPage
  // allotPage
// InsteadOfOrder
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  // 如果不是在微信浏览器中打开
  // if(!isweixin() && to.path !="/ctoast"){
  //   if(to.path == "/download"){
  //     next()
  //   }else{
  //     next({path:"/ctoast"})
  //   }
  // }
  // else{
  //   next()
  // }
  next()
})
export default router
